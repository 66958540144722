import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Slider from "react-slick"

import Seo from "../components/SEO"
import Wave from "../components/wave"
import Footer from "../components/footer"

const AboutPage = ({
  data,
  pageContext: { locale, alternateLanguages },
  location,
}) => {
  const aboutPrismic = data.prismicAboutpage

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 150,
        },
      },
    ],
  }

  return (
    <>
      <Seo
        title={aboutPrismic.data.seo_title}
        desc={aboutPrismic.data.seo_description}
        pathname={location.pathname}
        locale={locale}
        alternateLanguages={alternateLanguages}
      />
      <Wave title={aboutPrismic.data.title.text} />
      <section className=" relative z-10 px-10 max-w-3xl mx-auto mb-20 md:flex md:items-start    ">
        <div className="w-36 h-36 mx-auto">
          <GatsbyImage
            image={getImage(aboutPrismic.data.me_image)}
            alt={aboutPrismic.data.me_image.alt}
            className="rounded-full"
          />
          {/* <Img
            fluid={aboutPrismic.data.me_image.fluid}
            alt="Josselin"
            className="rounded-full"
          /> */}
        </div>
        <div className="flex-1 ml-10 mt-10 md:mt-0">
          <h2 className="text-2xl font-sans font-bold text-comet">
            {aboutPrismic.data.me_name.text}
          </h2>
          <p className=" mt-5 text-lg font-normal text-gray-500 font-serif z-10 relative">
            {aboutPrismic.data.me_description.text}
          </p>
        </div>
      </section>
      <section className="px-10 max-w-3xl mx-auto ">
        <h3 className="text-2xl font-sans font-bold text-comet text-center">
          {aboutPrismic.data.trust_title.text}
        </h3>

        <div className="md:flex mt-8">
          <Slider {...settings} style={{ width: "100%" }}>
            {aboutPrismic.data.client_trust.map(client => (
              <GatsbyImage
                image={getImage(client.client_image)}
                alt={client.client_image.alt}
                // style={{ width: "200px" }}
              />
            ))}
          </Slider>
        </div>
        {aboutPrismic.data.client_feedback.map(feedback => (
          <figure className="bg-white  mt-20 p-5 px-2 rounded-2xl shadow relative md:mx-10">
            <div className=" mx-auto w-full -mt-16 -ml-5  absolute flex justify-center">
              <GatsbyImage
                image={getImage(feedback.client_image)}
                alt={"alt"}
                className="flex w-24 h-24  border-8 border-white  rounded-full"
              />
            </div>
            <blockquote
              className="text-lg font-sans italic mt-10 text-comet"
              dangerouslySetInnerHTML={{
                __html: feedback.client_paragraph.html,
              }}
            />
            <figcaption
              className="text-lg font-sans text-comet text-right mt-5 pr-3"
              dangerouslySetInnerHTML={{
                __html: feedback.client_name_job.html,
              }}
            />
          </figure>
        ))}
      </section>
      <section className="px-10 pt-10 max-w-3xl mx-auto md:flex">
        {aboutPrismic.data.business_bloc.map((bloc, i) => {
          console.log(
            "bloc.business_description.html",
            bloc.business_description.html
          )
          return (
            <div
              key={i}
              className={`m-10 mb-20 flex-1 ${
                i % 2 ? "md:text-left" : "md:text-right"
              }`}
            >
              <h3 className="text-2xl font-sans font-bold text-comet">
                {bloc.business_title.text}
              </h3>
              <div
                className="mt-5 text-lg font-normal text-gray-500 font-serif z-10 relative"
                dangerouslySetInnerHTML={{
                  __html: bloc.business_description.html,
                }}
              />
            </div>
          )
        })}
      </section>
      {/* {aboutPrismic.data.title.text} */}
      <Footer posts={data.allPrismicBlogpost} />
    </>
  )
}

export default AboutPage

AboutPage.propTypes = {
  data: PropTypes.shape({
    prismicAboutpage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query AboutQuery($locale: String!) {
    prismicAboutpage(lang: { eq: $locale }) {
      data {
        seo_description
        seo_title
        title {
          text
        }
        me_name {
          text
        }
        me_image {
          alt
          gatsbyImageData(width: 300)
        }
        me_description {
          text
        }
        business_bloc {
          business_title {
            text
          }
          business_description {
            text
            html
          }
        }
        trust_title {
          text
        }
        client_trust {
          client_name
          client_image {
            gatsbyImageData(width: 300)
          }
        }
        client_feedback {
          client_paragraph {
            text
            html
          }
          client_image {
            alt
            gatsbyImageData(width: 300)
          }
          client_name_job {
            html
            richText
          }
        }
      }
      uid
      lang
    }

    allPrismicBlogpost(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title {
              text
              html
            }
            body {
              ... on PrismicBlogpostDataBodyContent {
                id
                slice_type
                slice_label
                primary {
                  richtext {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
