import PropTypes from "prop-types"
import React from "react"
import waveImg from "../../static/wavy_dots.svg"
import dayjs from "dayjs"
import "dayjs/locale/fr"

const Wave = ({ title, blog = false, last_publication_date = false }) => {
  dayjs.locale("fr")
  const datePost = dayjs(last_publication_date).format("DD  MMMM YYYY")

  return (
    <div
      className={`section  flex flex-col gradient relative h-80 md:min-h-400 0 ${
        blog ? "py-40" : "pt-24"
      }`}
    >
      <h1
        className={`text-3xl max-w-5xl font-sans font-bold m-auto px-10 text-center text-comet relative z-10 ${
          blog ? "order-3 text-left mt-0 mb-3" : ""
        }`}
      >
        {title}
      </h1>
      {blog && (
        <>
          <h2 className="order-2 text-3xl font-sans font-extralight  px-10 text-comet z-10 md:m-auto md:my-0">
            Blog Post
          </h2>
          <p className="order-4 mx-10 relative z-10 md:mx-auto">
            <small
              className=" text-sm font-serif text-comet px-2 py-1 bg-white rounded
          "
            >
              <time dateTime={last_publication_date}>{datePost}</time>
            </small>
          </p>
        </>
      )}
      <img
        className="absolute -bottom-0.5 w-full z-0"
        src={waveImg}
        alt="wave"
      />
    </div>
  )
}

Wave.propTypes = {
  title: PropTypes.string,
}

export default Wave
